import {
  UserSvcUsersService as UserService,
  UserSvcRolesPermissionsService as UserRolesPermissionsService,
  UserSvcRole,
} from '@kp/rest-api-javascript-sdk';
import { PaginationFilter } from '../common';
import { Role } from '../../features/users/common/constants';

export type UserRole = UserSvcRole;
type UserRoleInput = {
  userId: string;
  roleId: string;
};

// We ignore any other roles coming from the backend
export const availableRoles = [
  Role.CONTRACT_MANAGER,
  Role.DEVELOPER,
  Role.POWER_USER,
  Role.SALES_REPRESENTATIVE,
  Role.STANDARD_USER,
  Role.SUSTAINABILITY_OFFICER,
  Role.TECHNICIAN,
  Role.TENANT_ADMIN,
];

/**
 * List all user rolles
 *
 * @param filter  The filter parameters
 */
export const getUserRoles = async (filter: PaginationFilter = {}) =>
  UserRolesPermissionsService.getUserApiRoles(filter.skip, filter.limit);

/**
 * Add a new role to a user
 *
 * @param userId         The id of the user
 * @param roleId         The id of the role, which will be added to the user
 */
export const addUserRole = ({ userId, roleId }: UserRoleInput) =>
  UserService.postUserApiUsersRoles(userId, roleId, {});

/**
 * Delete a role from a user
 *
 * @param userId         The id of the user
 * @param roleId         The id of the role, which will be deleted from the user
 */
export const deleteUserRole = ({ userId, roleId }: UserRoleInput) =>
  UserService.deleteUserApiUsersRoles(userId, roleId);
