import React from 'react';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FancyCard } from '../../../components/FancyCard';
import { UserRole } from '../../../api/user';
import { CheckboxField, TextField } from '../../../components/Fields';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  rolesCard: {
    border: '1px solid',
    borderColor: Color.border,
    padding: '5px 16px 10px 16px',
    margin: '0px 0px 10px 0px',
    borderRadius: 5,
    background: Color.inputBackground,
  },
  invalidFeedback: {
    color: Color.error,
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

interface RoleWitStatus extends UserRole {
  enabled: boolean;
}
type UserRolesCreateProps = {
  type: 'edit' | 'invite';
  loading?: boolean;
};

export const UserForm: React.FC<UserRolesCreateProps> = ({ type, loading }) => {
  const { t } = useTranslation(['users', 'general']);
  const {
    control,
    formState: { errors },
  } = useFormContext<{ roles: RoleWitStatus[] }>();
  const { fields } = useFieldArray({
    control,
    name: 'roles',
  });

  return (
    <>
      {type === 'invite' && (
        <div className={classes.section}>
          <FancyCard
            title={t('users:invitation.create.title')}
            loading={loading}
          >
            <Grid
              container
              spacing={1}
              direction="column"
              className={classes.cardContent}
            >
              <Grid item xs={12} md={12}>
                <TextField
                  disabled={loading}
                  id="email"
                  name="email"
                  label={t('users:invitation.field.email')}
                />
              </Grid>
            </Grid>
          </FancyCard>
        </div>
      )}

      <div className={classes.section}>
        <FancyCard title={t('users:invitation.field.roles')} loading={loading}>
          <Grid
            container
            spacing={1}
            direction="column"
            className={classes.cardContent}
          >
            <Grid item xs={12} md={12}>
              {fields.map((role, index) => (
                <div className={classes.rolesCard} key={role.id}>
                  <CheckboxField
                    disabled={loading}
                    name={`roles.${index}.enabled`}
                    data-testid={role.id}
                    Label={{
                      label: t(`general:userRoles.${role.name}.label`),
                    }}
                  />
                  <div>{t(`general:userRoles.${role.name}.description`)}</div>
                </div>
              ))}
              {errors.roles && (
                <div className={classes.invalidFeedback}>
                  {errors.roles.message}
                </div>
              )}
            </Grid>
          </Grid>
        </FancyCard>
      </div>
    </>
  );
};
