/*
HINT: Keep this list in sync with the translation.json file for 
t('general:userRoles.contract-manager.label')
t('general:userRoles.contract-manager.description')
t('general:userRoles.developer.label')
t('general:userRoles.developer.description')
t('general:userRoles.power-user.label')
t('general:userRoles.power-user.description')
t('general:userRoles.sales-representative.label')
t('general:userRoles.sales-representative.description')
t('general:userRoles.standard-user.label')
t('general:userRoles.standard-user.description')
t('general:userRoles.sustainability-officer.label')
t('general:userRoles.sustainability-officer.description')
t('general:userRoles.technician.label')
t('general:userRoles.technician.description')
t('general:userRoles.tenant-admin.label')
t('general:userRoles.tenant-admin.description')
*/

export enum Role {
  CONTRACT_MANAGER = 'contract-manager',
  DEVELOPER = 'developer',
  POWER_USER = 'power-user',
  SALES_REPRESENTATIVE = 'sales-representative',
  STANDARD_USER = 'standard-user',
  SUSTAINABILITY_OFFICER = 'sustainability-officer',
  TECHNICIAN = 'technician',
  TENANT_ADMIN = 'tenant-admin',
}
