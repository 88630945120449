import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../../components/FancyCard';
import { InfoList } from '../../../../components/InfoList';
import { InfoListItem } from '../../../../components/InfoListItem';
import { ActionsBar } from '../../../../components/ActionsBar';
import { UserInvitationStatus } from '../../../../api/user';
import { Color } from '../../../../constants/Colors';
import { FormattedDate } from '../../../../components/FormattedDate';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
  },
});

type UserInvitationDetailsProps = {
  id: string;
  email: string;
  status: string;
  roleNames?: string[];
  createdAt?: string;
  updatedAt?: string;
  loading?: boolean;
  onDelete?: () => void;
  onResend?: () => void;
};
export const UserInvitationDetails: React.FC<UserInvitationDetailsProps> = ({
  id,
  email,
  status,
  roleNames,
  createdAt,
  updatedAt,
  onDelete,
  onResend,
  loading,
}) => {
  const { t } = useTranslation(['users', 'general']);

  return (
    <>
      <ActionsBar>
        {!loading &&
          status === UserInvitationStatus.Pending &&
          email !== '' && (
            <Button
              className={classes.actionButton}
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<Icons.Notifications />}
              onClick={onResend}
            >
              {t('users:invitation.actions.resendButton')}
            </Button>
          )}
        {!loading && status === UserInvitationStatus.Pending && (
          <Button
            className={classes.actionButton}
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<Icons.Delete />}
            onClick={onDelete}
          >
            {t('users:invitation.actions.deleteButton')}
          </Button>
        )}
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={6}>
              <FancyCard
                title={t('users:invitation.details.info.title')}
                loading={loading}
              >
                <InfoList>
                  <InfoListItem term={t('users:invitation.details.info.id')}>
                    {id}
                  </InfoListItem>
                  <InfoListItem term={t('users:invitation.details.info.email')}>
                    {email}
                  </InfoListItem>
                  <InfoListItem
                    term={t('users:invitation.details.info.status')}
                  >
                    {status === UserInvitationStatus.Pending ? (
                      <div className={classes.status}>
                        <Icons.Autorenew
                          data-testid="status-icon-pending"
                          htmlColor={Color.mediumGrey}
                        />
                        {t('users:invitation.status.pending')}
                      </div>
                    ) : (
                      <div className={classes.status}>
                        <Icons.AssignmentTurnedIn
                          data-testid="status-icon-accepted"
                          htmlColor={Color.ok}
                        />
                        {t('users:invitation.status.accepted')}
                      </div>
                    )}
                  </InfoListItem>
                  <InfoListItem
                    term={t('users:invitation.details.info.createdAt')}
                  >
                    <FormattedDate value={createdAt} />
                  </InfoListItem>
                  <InfoListItem
                    term={t('users:invitation.details.info.updatedAt')}
                  >
                    <FormattedDate value={updatedAt} />
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
            {roleNames && (
              <Grid item xs={6}>
                <FancyCard
                  title={t('users:invitation.details.roles.title')}
                  loading={loading}
                >
                  <InfoList>
                    <InfoListItem
                      term={t('users:invitation.details.roles.name')}
                    >
                      {roleNames.map((roleName, index) => (
                        <ListItemText key={index.toString()}>
                          {t(`general:userRoles.${roleName}.label`)}
                        </ListItemText>
                      ))}
                    </InfoListItem>
                  </InfoList>
                </FancyCard>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
