import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import {
  addUserRole,
  deleteUserRole,
  getUserRoles,
  getUser,
  availableRoles,
} from '../../../api/user';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { entityLocation } from '../../../utils/entity';
import { UserEdit, UserEditRolesData } from './UserEdit';
import { NotFound } from '../../errorScreens';
import { ErrorMessage } from '../../../components/Errors';
import { Role } from '../common/constants';

export const UserEditContainer: React.FC = () => {
  const { t } = useTranslation(['users', 'general']);
  const { setTitle } = useHeader();
  const { userId } = useParams();
  const navigate = useNavigate();

  const {
    data: responseRoles,
    isLoading: loadingRoles,
    error: errorRoles,
  } = useQuery({
    queryKey: ['getUserRoles'],
    queryFn: () => getUserRoles(),
    onError: (err: ApiError) => err,
  });

  const {
    data: response,
    isFetching: loading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getUser', userId],
    queryFn: () => getUser(userId),
    onError: (err: ApiError) => err,
  });

  const user = response?.data ?? null;
  const rolesWithStatus = useMemo(
    () =>
      (responseRoles?.data ?? [])
        .filter((role) => availableRoles.includes(role.name as Role))
        .map((role) => ({
          ...role,
          enabled: !!user?.roles.find((userRole) => role.id === userRole.id),
        })),
    [responseRoles?.data, user?.roles],
  );
  const hierarchy = user
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.USER,
            id: userId,
            name: `${user.firstName} ${user.lastName}`,
          },
        ],
        t,
      ).concat({
        title: t('users:breadcrumb.editRoles'),
        location: entityLocation(Entities.USER, `${userId}/edit`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: user ? `${user.firstName} ${user.lastName}` : undefined });
  }, [setTitle, user]);

  const handleDiscard = () => {
    navigate(entityLocation(Entities.USER, userId));
  };

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState<ApiError>();

  const { mutateAsync: addRole } = useMutation({
    mutationFn: addUserRole,
    onError: (err: ApiError) => err,
  });
  const { mutateAsync: deleteRole } = useMutation({
    mutationFn: deleteUserRole,
    onError: (err: ApiError) => err,
  });

  const handleSubmit = async (selectedUserRoles: UserEditRolesData) => {
    const rolesToRemove: string[] = [];
    const rolesToAdd: string[] = [];

    setLoadingUpdate(true);

    selectedUserRoles.roles.forEach(({ id: userRoleId, enabled }) => {
      if (!enabled && user?.roles.find(({ id }) => id === userRoleId))
        rolesToRemove.push(userRoleId);
      if (enabled && !user?.roles.find(({ id }) => id === userRoleId))
        rolesToAdd.push(userRoleId);
    });

    const errors: ApiError[] = [];

    const deleteRolesPromise = Promise.all(
      rolesToRemove.map((roleId) =>
        deleteRole({ userId, roleId }).catch((e) => errors.push(e)),
      ),
    );
    const addRolesPromise = Promise.all(
      rolesToAdd.map((roleId) =>
        addRole({ userId, roleId }).catch((e) => errors.push(e)),
      ),
    );

    await Promise.allSettled([deleteRolesPromise, addRolesPromise]);

    setLoadingUpdate(false);

    if (errors.length > 0) {
      setErrorUpdate(errors[0]);
      refetch();
      return false;
    }
    navigate(entityLocation(Entities.USER, userId));
    return true;
  };

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('users:details.notFound.title') ?? ''}
          subtitle={t('users:details.notFound.subtitle') ?? ''}
          buttonText={t('users:details.notFound.buttonText') ?? ''}
          buttonOnClick={() => navigate(Entities.USER.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <UserEdit
        loading={loading || loadingUpdate || loadingRoles}
        roles={rolesWithStatus}
        onDiscard={handleDiscard}
        onSubmit={handleSubmit}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.title')}
        errors={[errorUpdate, errorRoles]}
      />
    </>
  );
};
